<template>
  <div>

    <h2 class="createtitletext">
      {{ localize('TEXT_OVERVIEW') }}
    </h2>

    <div class="flex-row-wrap">

      <div :class="respLayout">

          <div class="create-sub-header">
            {{ localize('CREATE_SERVICE_HEADER') }}
          </div>

          <div class="create-upload-container">
            <div class="sub-script">
              {{ localize('CREATE_CHOOSE_FILE') }}
            </div>
            <form class="create-upload-box">
              <label
                class="create-service-file-upload"
                for="create-service-file-upload"
              />
              <input
                id="create-service-file-upload"
                type="file"
                accept="image/*,video/*"
                @change="addCreativeFiles"
              >
            </form>
          </div>

          <div class="define-left">
            <CInput
              primaryColor="#505050"
              class="margin-top"
              :value="product.title"
              :title="localize('TEXT_TITLE')"
              :placeholder="localize('TEXT_TITLE')"
              @inputValue="product.title = $event"
            />
          </div>

          <div class="define-left">
            <CTextArea
              primaryColor="#505050"
              class="service-description"
              :value="product.descriptionpreview"
              :title="localize('TEXT_OFFER_DESCRIPTION')"
              :placeholder="localize('TEXT_SERVICE_DESCRIPTION')"
              @inputValue="product.descriptionpreview = $event"
            />
          </div>

      </div>

      <div class="preview-container">
        <div class="create-sub-header">
          {{ localize('CREATE_SERVICE_PREVIEW') }}
        </div>

        <ServiceCard
          v-if="profile"
          class="edit-service-card-preview"
          :profile="profile"
          :product="product"
          :product-media="productMedia"
          :key="productMedia.length"
        >
        </ServiceCard>

      </div>

    </div>

    <div
      class="local-container"
    >
      <div class="local-switch-container">
        <div v-if="product.local === true" class="c-input-title">
          {{ localize('TEXT_LOCAL') }}
        </div>
        <div v-else class="c-input-title">
          {{ localize('TEXT_ONLINE') }}
        </div>
        <CSwitch
          class="local-switch"
          primaryColor="#505050"
          :checked="product.local"
          @inputValue="setLocal($event)"
        />
      </div>
      <CDropdown
        v-if="product.local == true"
        :title="localize('TEXT_COUNTRY')"
        :toggler-text="product.country"
        class="create-dropdown-item"
      >
        <CDropdownItem
          v-for="i in countries"
          :key="i"
          @click.native="product.country = i.name"
        >
          {{ i.name }}
        </CDropdownItem>
      </CDropdown>

      <CInput
        v-if="product.local == true"
        class="location-input"
        :title="localize('TEXT_CITY')"
        :placeholder="product.city"
        @inputValue="product.city = $event"
      />
      <div
        class="online-local-text"
        v-if="product.local == true"
      >
        {{localize('LOCAL_GIG_WHAT')}}
      </div>
      <div
        v-else
        class="online-local-text"
      >
        {{localize('ONLINE_GIG_WHAT')}}
      </div>
    </div>

    <h2 class="createtitletext">
      {{ localize('TEXT_MEDIA') }}
    </h2>

    <div
      :id="productContainer"
    >

      <Carousel
        class="service-carousel"
        ref="carousel"
        :wrap-around="false"
        :items-to-show="1"
      >

        <Slide
          v-for="(n, i) in productMedia"
          :key="i"
        >

          <div
            :id="'product-banner-' + product.id + '-' + i"
            class="product-banner-desktop"
          >
            <video
              v-if="n.media_filename_preview"
              class="product-video"
              loop="true"
              autoplay="true"
              :poster="getURL(n.media_filename_preview)"
              :src="getURL(n.media_filename)"
              muted
            />
          </div>

        </Slide>

        <template #addons>
          <Navigation />
          <Pagination />
        </template>
      </Carousel>

    </div>

    <div class="service-thumbnails">

      <div
        v-for="(n, i) in productMedia"
        class="product-selection"
      >
        <div class="product-thumbnail-container">
          <div
            class="media-preview" 
            v-if="n.media_filename_preview"
            @click="selectCard(i)"
          >
            <img class="media-thumbnail" :src="getURL(n.media_filename_preview)" />
          </div>

          <div
            class="media-thumbnail" 
            v-else-if="n.media_filename"
            @click="selectCard(i)"
          >
            <img class="media-thumbnail" :src="getURL(n.media_filename)" />
          </div>

          <div class="flex-row mont-delete">
            <div
              class="delete-button-small"
              @click="deleteProductMedia(n.id,i)"
            >
              {{localize('TEXT_DELETE')}}
            </div>
          </div>
        </div>
      </div>
      <div
        class="media-preview-thumbnail" 
      >
        <form class="create-upload-box-thumbnail">
          <label
            class="create-service-file-upload-thumbnail"
            for="create-service-file-upload"
          >
            <div class="add-media-thumbnail">
              +
            </div>
          </label>
          <input
            id="create-service-file-upload-thumbnail"
            type="file"
            accept="image/*,video/*"
            @change="addCreativeFiles"
          >
        </form>
      </div>
    </div>

    <h2 class="createtitletext">
      {{ localize('TEXT_OFFERS') }}
    </h2>

    <div :class="optionsContainer">

      <div
        v-for="(i,k) in product.options" :key="k"
        :class="respLayout"
      >
        <div v-if="serverConfig.view != 'mobile'" class="delete-button-small" @click="deleteOption(k)">
          {{localize('TEXT_DELETE')}}
        </div>
        <CInput
          class="location-input"
          :value="k"
          :title="localize('TEXT_TITLE')"
          :placeholder="k"
          @focus="oldKey = k"
          @blur="changeOptionKey(newKey, oldKey)"
          @inputValue="newKey = $event"
        />
<!--
          @inputValue="(event) => changeOptionKey(event, k)"
-->
        <CTextArea
          class="offer-description"
          :value="product.options[k].description"
          :title="localize('TEXT_PREVIEW_DESCRIPTION')"
          :placeholder="localize('TEXT_SERVICE_DESCRIPTION')"
          @inputValue="product.options[k].description = $event"
        />
        <div>

<!--
          <div class="flex-row">
            <CDropdown
              :title="localize('TEXT_DAYS_DUE')"
              :toggler-text="product.options[k].days"
              class="create-dropdown-item"
            >
              <CDropdownItem
                v-for="i in 30"
                :key="i"
                @click.native="product.options[k].days = i"
              >
                {{ i }}
              </CDropdownItem>
            </CDropdown>

            <CDropdown
              :title="localize('TEXT_REVISIONS')"
              :toggler-text="product.options[k].revisions"
              class="create-dropdown-item"
            >
              <CDropdownItem
                v-for="i in 10"
                :key="i"
                @click.native="product.options[k].revisions = i"
              >
                {{ i }}
              </CDropdownItem>
            </CDropdown>
          </div>
-->

          <div class="edit-features">
            <div class="flex-row separate">
              <CInput
                class="margin-top"
                :title="localize('TEXT_FEATURES')"
                :placeholder="localize('TEXT_TITLE')"
                @inputValue="currentFeature = $event"
              />
              <div
                class="add-features"
                @click.native="product.options[k].features.push(currentFeature)"
              >
                {{localize('TEXT_ADD')}} <b> + </b>
              </div>
            </div>
            <div
              v-for="(j,i) in product.options[k].features"
              class="flex-row"
            >
              <div>
                <b>{{ j }}</b>
              </div>
              <div
                class="rem-features"
                @click.native="product.options[k].features.splice(i,1)"
              >
                <b>-</b>
              </div>
            </div>
          </div>

          <div class="flex-row">
            <CInput
              class="margin-top"
              :value="product.options[k].price"
              :title="localize('TEXT_PRICE')"
              :placeholder="localize('TEXT_TITLE')"
              @inputValue="product.options[k].price = $event"
            />
            <div class="local-switch-container">
              <div v-if="product.options[k].hourly == true" class="c-input-title">
                {{ localize('TEXT_HOURLY_RATE') }}
              </div>
              <div v-else class="c-input-title">
                {{ localize('TEXT_FIXED_RATE') }}
              </div>
              <CSwitch
                class="local-switch"
                primaryColor="#505050"
                :checked="product.options[k].hourly"
                @inputValue="product.options[k].hourly = $event"
              />
            </div>
          </div>

          <div class="order-title">
            <h3>{{k}}</h3>
          </div>

          <div class="offer-container-outer">

            <div class="offer-container-inner">

              <div class="order-description">
                {{ product.options[k].description }}
              </div>

              <div class="flex-col">

                <div class="order-detail">
                  <b>{{localize('TEXT_DAYS_DUE')}} : {{product.options[k].days}}</b>
                </div>
                <div class="order-detail">
                  <b>{{localize('TEXT_REVISIONS')}} : {{product.options[k].revisions}}</b>
                </div>

              </div>


              <div class="flex-col-apart">

                <div>

                  <div
                    v-for="(j,i) in product.options[k].features"
                    class="flex-row"
                  >
                    <div
                      class="feature-tick"
                      v-html="getRawIcon('CIRCLE_CHECK')"
                    />
                    <div>
                      {{ j }}
                    </div>
                  </div>

                </div>

                <div class="price">
                  <b>{{localize('TEXT_PRICE')}} $ {{product.options[k].price}}</b>
                </div>

              </div>

            </div>

          </div>

          <div class="delete-button-small" @click="deleteOption(k)">
            {{localize('TEXT_DELETE')}}
          </div>

        </div>

      </div>

      <div
        v-if="Object.keys(product.options).length < 3"
        class="add-option"
        @click="addOption()"
      >
        {{localize('TEXT_ADD')}}
      </div>

    </div>

    <h2 class="createtitletext">
      {{ localize('TEXT_MAIN_DESCRIPTION') }}
    </h2>

    <div class="quill-editor">
        <!--toolbar="full"-->
      <QuillEditor
        v-model:content="product.description"
        class="quill-input"
        content-type="html"
        :modules="modules"
        :toolbar="toolbar"
        theme="snow"
        enable="true"
      />
    </div>

    <div class="flex-row margin-top-100">

      <div
        class="create-save-service-button"
        @click="saveProduct"
      >
        {{ localize('TEXT_SAVE') }}
      </div>

      <div class="save-under-account">
        {{localize('SAVE_LISTED_ACCOUNT') }} : <b> {{ product.account }} </b>
      </div>

      <div class="local-switch-container">
        <div v-if="product.enabled === false" class="c-input-title">
          {{ localize('TEXT_DISABLED') }}
        </div>
        <div v-else class="c-input-title">
          {{ localize('TEXT_ENABLED') }}
        </div>
        <CSwitch
          class="local-switch"
          primaryColor="#505050"
          :checked="product.enabled"
          @inputValue="setEnabled($event)"
        />
      </div>

    </div>

    <Transition>
      <CModal
        v-if="showValidateModal"
        color="#f9b115"
        title="Validation Error"
        @close="showValidateModal = false"
      >
        <template #header>
          <div> {{ localize('VALIDATION_ERROR') }} </div>
        </template>
        <template #body>
          <div>
            {{validateError}}
          </div>
        </template>
        <template #footer>
          <div class="hidden" />
        </template>
      </CModal>
    </Transition>

    <Transition>
      <CModal
        v-if="showAddModal"
        color="#f9b115"
        title="Please Wait"
        @close="showAddModal = false"
      >
        <template #header>
          <div> {{ localize('TEXT_ADD_SERVICE') }} </div>
        </template>
        <template #body>
          <div>
            {{ localize(showAddMessage) }}
          </div>
        </template>
        <template #footer>
          <div class="hidden" />
        </template>
      </CModal>
    </Transition>

  </div>
</template>
<script>

import {iconStore} from "@/stores/icon";
import {translationStore} from "@/stores/translation";
import {inject, onMounted, reactive, ref, toRaw, toRefs, unref, watch, watchEffect} from "vue";
import {Carousel, Navigation, Pagination, Slide} from "vue3-carousel";
import {QuillEditor} from "@vueup/vue-quill";
import BlotFormatter from "quill-blot-formatter";
import ImageUploader from "quill-image-uploader";
import Axios from "axios";
import FormData from "form-data";
import ServiceCard from "./ServiceCard.vue";
import {ERC20FactoryABI} from "../abi.js";
import countries from "../countries.ts";

export default {
    "name": "CreateService",
    "components": {
        Carousel,
        Navigation,
        Pagination,
        Slide,
        QuillEditor,
        ServiceCard
    },
    "props": {
        "listType": {
            "type": String,
            "default": ""
        },
        "category": {
            "type": String,
            "default": ""
        },
        "subCategory": {
            "type": String,
            "default": ""
        },
        "product": {
            "type": Object,
            "default": {
                "id":"",
                "category":"",
                "seller": "",
                "account": "",
                "image":"",
                "video":"",
                "title":"",
                "local":false,
                "country":"",
                "city":"",
                "descriptionpreview":"",
                "description":"",
                "options": {
                    basic: {
                      price:20,
                      description:'',
                      revisions:1,
                      days:2,
                      features:[]
                    },
                    regular: {
                      price:50,
                      description:'',
                      revisions:2,
                      days:2,
                      features:[]
                    },
                    premium: {
                      price:150,
                      description:'',
                      revisions:4,
                      days:2,
                      features:[]
                    }
                },
                "media":[]
            }

        },

    },
    setup (props) {

        const translation = translationStore(),
            serverConfig = inject("serverConfig"),
            route = inject("route"),
            router = inject("router"),
            web3 = inject("web3"),
            userInfo = inject("userInfo"),
            carousel = ref(),
            icon = iconStore(),
            showAddMessage = ref(),
            showAddModal = ref(),
            product = ref(props.product),
            profile = ref(),
            oldKey = ref(),
            newKey = ref(),
            respLayout = ref('resp-layout-desktop'),
            optionsContainer = ref('options-container-desktop'),
            genRandStr = function () {

                return (new Date().getSeconds() * Math.random()).toString(36).replace(
                    ".",
                    ""
                );

            },
            toolbar = [
                ["bold", "italic", "underline", "strike"],
                ["blockquote", "code-block"],
                [{ header: 1 }, { header: 2 }],
                [{ list: "ordered" }, { list: "bullet" }],
                [{ script: "sub" }, { script: "super" }],
                [{ indent: "-1" }, { indent: "+1" }],
                [{ direction: "rtl" }],
                [{ size: ["small", false, "large", "huge"] }],
                [{ header: [1, 2, 3, 4, 5, 6, false] }],
                [{ color: [] }, { background: [] }],
                [{ font: [] }],
                [{ align: [] }],
                ["clean"],
                ["link", "image"],
            ],
            modules = {
                toolbar: [
                    ["bold", "italic", "underline", "strike"],
                    ["blockquote", "code-block"],
                    [{ header: 1 }, { header: 2 }],
                    [{ list: "ordered" }, { list: "bullet" }],
                    [{ script: "sub" }, { script: "super" }],
                    [{ indent: "-1" }, { indent: "+1" }],
                    [{ direction: "rtl" }],
                    [{ size: ["small", false, "large", "huge"] }],
                    [{ header: [1, 2, 3, 4, 5, 6, false] }],
                    [{ color: [] }, { background: [] }],
                    [{ font: [] }],
                    [{ align: [] }],
                    ["clean"],
                    ["link", "image"],
                ],
                video: {

                    "name": "video",
                    "module": BlotFormatter,
                    "options": {
                      video: (url) => {

                        const videoID = extractVideoID(url);
                        if (videoID) {

                          switch (videoInfo.platform) {
                            case 'youtube':
                              embedUrl = `https://www.youtube.com/embed/${videoInfo.id}`;
                              break;
                            case 'dailymotion':
                              embedUrl = `https://www.dailymotion.com/embed/video/${videoInfo.id}`;
                              break;
                            case 'twitch':
                              embedUrl = `https://player.twitch.tv/?video=v${videoInfo.id}`;
                              break;
                            default:
                              throw new Error('Unsupported platform');

                          }
                          return `<iframe
                            class="ql-video"
                            frameborder="0"
                            allowfullscreen="true"
                            src="${embedUrl}"
                            sandbox="allow-same-origin"
                            width="100%"
                            height="500px"
                          ></iframe>`;

                        } else {
                                
                          validateError.value = "Supported videos, are Youtube, Vimeo, Twitch, & Dailymotion."
                          showValidateModal.value = true;

                        }

                      },

                   },

                },
                image: {

                    "name": "imageUploader",
                    "module": ImageUploader,
                    "options": {
                        "upload": (file) => {

                            return new Promise((resolve, reject) => {

                                const formData = new FormData();
                                formData.append(
                                    "file",
                                    file
                                );

                                Axios.post(
                                    "/uploadImage",
                                    formData
                                ).
                                    then((res) => {

                                        console.log(res);
                                        resolve(res.data.url);

                                    }).
                                    catch((err) => {

                                        reject("Upload failed");
                                        console.error(
                                            "Error:",
                                            err
                                        );

                                    });

                            });

                        }

                    }

                }
            },
            extractVideoID = (url) => {

console.log('extra')

                // Youtube
                const youtubeRegex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
                const youtubeMatch = url.match(youtubeRegex);
                if (youtubeMatch) return { platform: 'youtube', id: youtubeMatch[1] };

                // Dailymotion
                const dailymotionRegex = /(?:https?:\/\/)?(?:www\.)?dailymotion\.com\/video\/([a-zA-Z0-9]+)/;
                const dailymotionMatch = url.match(dailymotionRegex);
                if (dailymotionMatch) return { platform: 'dailymotion', id: dailymotionMatch[1] };

                // Twitch
                const twitchRegex = /(?:https?:\/\/)?(?:www\.)?twitch\.tv\/videos\/(\d+)/;
                const twitchMatch = url.match(twitchRegex);
                if (twitchMatch) return { platform: 'twitch', id: twitchMatch[1] };

                return null;

            },
            videoTypes = reactive([
                "video/mp4",
                "video/mpeg",
                "video/x-msvideo",
                "video/webm"
            ]),
            imageTypes = reactive([
                "image/gif",
                "image/jpeg",
                "image/avif",
                "image/png",
                "image/svg",
                "image/svg+xml",
                "image/webp"
            ]),
            maxVideoSize = ref("1000000000"),
            maxImageSize = ref("10000000"),
            detail = ref(),
            currentFeature = ref(),
            selectedMedia = ref(0),
            showTokenModal = ref(false),
            showValidateModal = ref(false),
            validateError = ref(),
            productMedia = reactive(props.product.media),
            productContainer = ref(),
            addOption = function (k) {

                product.value.options['custom'] = {};

            },
            deleteOption = function (k) {

                delete product.value.options[k];

            },
            changeOptionKey = function(n,o) {

                const new_ = n;
                const old = o;

                if (newKey.value != undefined
                    && oldKey.value != undefined
                    && oldKey.value != newKey.value) {

                    var x = JSON.parse(JSON.stringify(product.value.options[old]));
                    console.log(x);
                    delete product.value.options[old];
                    product.value.options[new_] = x;

                }

                newKey.value = undefined;
                oldKey.value = undefined;

            },
            getMyListings = async function (callback) {

                const accounts = await ethereum.request({"method": "eth_requestAccounts"}),
                    addr = accounts[0];

                Axios.get(
                    `${process.env.VUE_APP_SERVER_URI}getMyTokens`,
                    {

                        "params": {

                            addr

                        }

                    }
                ).
                    then((response) => {

                        if (response.status === 200) {

                            let i = 0;
                            for (i; i < response.data.length; i++) {

                            }

                            callback();

                        }

                    }).
                    catch((error) => {

                        throw error;

                    });

            },
            deleteProductMedia = function (id, index) {

                Axios.get(
                    `${process.env.VUE_APP_SERVER_URI}deleteProductMedia`,
                    {

                        "params": {

                            id

                        }

                    }
                ).
                    then((response) => {

                        if (response.status === 200) {

                            productMedia.splice(
                                index,
                                1
                            );
                            product.value.media = productMedia;

                        } else {

                            callback();

                        }

                    }).
                    catch((error) => {

                        throw error;

                    });

            },
            getService = function (product_id) {

                Axios.get(
                    `${process.env.VUE_APP_SERVER_URI}getService`,
                    {

                        "params": {

                            product_id

                        }

                    }
                ).
                    then((response) => {

                        if (response.status === 200) {

                            product.value = response.data

                        }

                        if (!product.value.city) {

                            product.value.city = profile.value.city;

                        }


                    }).
                    catch((error) => {

                        throw error;

                    });

            },
            getAccount = async function () {

                const acc = await web3.value.eth.getAccounts();

                if (acc.length > 0) {

                    return acc[0];

                } else if (window.eth) {

                    const accounts = await ethereum.request({"method": "eth_requestAccounts"});
                    return accounts[0];

                }

                console.log("window.open(https://metamask.io");
                window.open("https://metamask.io");


            },
            addCreativeFiles = function (e) {

                const files = e.target.files;
                // it's a for loop but iirc it only accepts one upload at a time?
                for (let i = 0; i < files.length; i++) {

                    const file = files[i];

                    if (imageTypes.indexOf(files[i].type) > -1) {

                        getMediaDimensions(
                            file,
                            "image",
                            (d) => {

                                const media = {};
                                media.dimensions = d;
                                media.loading = true;

                                addProductMedia(
                                    file,
                                    "image",
                                    (r) => {

                                        media.id = r.id;
                                        media.video = false;
                                        media.loading = false;
                                        media.media_filename = r.media_filename;
                                        media.media_filename_preview = r.media_filename_preview;
                                        media.original_filename = r.original_filename;
                                        media.url = r.url;
                                        productMedia.push(media);

                                        // important, update the product json
                                        product.value.media = productMedia;

                                        // set default;
                                        product.value.preview = r.media_filename_preview;
                                        product.value.image = r.media_filename;

                                    }
                                );

                            }
                        );

                    } else if (videoTypes.indexOf(files[i].type) > -1) {

                        const file = files[i];

                        getMediaDimensions(
                            file,
                            "video",
                            (d) => {

                                const media = {};
                                media.dimensions = d;
                                media.loading = true;

                                addProductMedia(
                                    file,
                                    "video",
                                    (r) => {

                                        // delay as the server side image may not be ready
                                        setTimeout(
                                            () => {

                                                media.id = r.id;
                                                media.video = true;
                                                media.loading = false;
                                                media.media_filename = r.media_filename;
                                                media.media_filename_preview = r.media_filename_preview;
                                                media.original_filename = r.original_filename;
                                                media.url = r.url;
                                                productMedia.push(media);

                                                // important, update the product json
                                                product.value.media = productMedia;

                                                // set default;
                                                product.value.preview = r.media_filename_preview;
                                                product.value.video = r.media_filename;

                                            },
                                            3000
                                        );

                                    }

                                );

                            }

                        );

                    } else {

                        console.log("not a supported type");
                        return;

                    }

                }

            },
            addProductMedia = async function (file, t, callback) {

                const data = new FormData();

                data.append(
                    "file",
                    file
                );
                data.append(
                    "type",
                    t
                );

                Axios.post(
                    `${process.env.VUE_APP_SERVER_URI}addProductFile`,
                    data,
                    {

                        "headers": {

                            "Content-Type": "multipart/form-data"

                        }

                    }
                ).
                    then((response) => {

                        if (response.status === 200) {

                            callback(response.data);

                        } else {

                            callback();

                        }

                    }).
                    catch((error) => {

                        throw error;

                    });

            },
            validateService = function () {

                if (product.value.title.length < 5) {

                    validateError.value = "Please Provide a descriptive title."
                    showValidateModal.value = true;
                    return;

                }

                if (!product.value.category) {

                    validateError.value = "Please select a category."
                    showValidateModal.value = true;
                    return;

                } else {
                    // TODO FOR NON SERVICE CARDS, IF SUBCATEGORY IS REQUIRED
                }

                if (!product.value.seller) {

                    validateError.value = "Please setup your profile and a valid username."
                    showValidateModal.value = true;
                    return;

                }

                if (!product.value.seller) {

                    validateError.value = "Please login and connect a wallet."
                    showValidateModal.value = true;
                    return;

                }

                if (product.value.descriptionpreview.length < 10) {

                    validateError.value = "Please create a longer description preview."
                    showValidateModal.value = true;
                    return;

                }

                if (product.value.description.length < 100) {

                    validateError.value = "Please create a longer main description."
                    showValidateModal.value = true;
                    return;

                }

                for (var i in product.value.options) {

                    if (!parseInt(product.value.options[i].price) && parseInt(product.value.options[i].price) < 5) {

                        validateError.value = "Please set a price of at least 5 dollars."
                        showValidateModal.value = true;
                        return;

                    }

                    if (product.value.options[i].description.length < 10) {

                        validateError.value = "Please set a longer description for your offerings."
                        showValidateModal.value = true;
                        return;

                    }

                    if (product.value.options[i].features.length < 1) {

                        validateError.value = "Please set a least one feature for each offering."
                        showValidateModal.value = true;
                        return;

                    }

                }

                if (product.value.media.length < 1) {

                    validateError.value = "Please add media to your service."
                    showValidateModal.value = true;
                    return;

                }

                return true;

            },
            saveProduct = function () {

                product.value.listType = props.listType;

                product.value.category = props.category;

                product.value.subCategory = props.subCategory;

                if (!product.value.seller) {

                    product.value.seller = userInfo.username;

                }

                if (!product.value.account) {

                    product.value.account = userInfo.accounts[0];

                }

                if (validateService() != true) {

                    return;

                }

                Axios.post(
                    `${process.env.VUE_APP_SERVER_URI}addService`,
                    {
                        product:product.value
                    }
                ).
                    then((response) => {

                        if (response.status === 200) {

                            product.value.id = response.data.id;

                            if (parseInt(product.value.id)) {

                                showAddMessage.value = "SERVICE_UPDATED_SUCCESS";
                                showAddModal.value = true;

                            } else {

                                showAddMessage.value = "SERVICE_ADDED_SUCCESS";
                                showAddModal.value = true;

                            }

                            setTimeout(()=>{

                                gotoPath('services/' + product.value.id);

                            },1000);

                        } else {

                            showAddMessage.value = "ERROR";
                            showAddModal.value = true;

                        }

                    }).
                    catch((error) => {

                        throw error;

                    });

            },
            selectCard = function (i) {

                productContainer.value = `product-container-${product.value.id}`;

                selectedMedia.value = i;

                if (i.media_filename_preview) {

                    product.value.video = productMedia[i].media_filename;
                    product.value.image = productMedia[i].media_filename_preview;

                } else {

                    product.value.video = undefined;
                    product.value.image = productMedia[i].media_filename;

                }
                carousel.value.slideTo(i);

            },
            getURL = function (endpoint) {

                return `${process.env.VUE_APP_SERVER_URI + endpoint}`;

            },
            getMediaDimensions = async function (f, t, c) {

                const dimensions = {};
                if (t === "video") {

                    const $video = document.createElement("video");
                    $video.src = URL.createObjectURL(f);
                    $video.addEventListener(
                        "loadedmetadata",
                        function (e) {

                            console.log(e);
                            dimensions.x = this.videoWidth;
                            dimensions.y = this.videoLength;
                            c(dimensions);

                        }
                    );
                    setTimeout(
                        () => {

                            $video.removeEventListener(
                                "loadedmetadata",
                                () => {},
                                false
                            );

                        },
                        3000
                    );

                } else if (t === "image") {

                    const img = new Image();
                    img.src = URL.createObjectURL(f);
                    img.onload = function () {

                        dimensions.x = img.width;
                        dimensions.y = img.height;
                        c(dimensions);

                    };

                } else {

                    c();

                }

            },
            getRawIcon = function (i) {

                return icon.get(`${i}`);

            },
            gotoPath = function (t) {

                router.push({"path": `/${t}`}).catch((err) => {

                    throw err;

                });

            },
            setLocal = function (e) {

                product.value.local = e;

            },
            setEnabled = function (e) {

                product.value.enabled = e;

                Axios.get(
                    `${process.env.VUE_APP_SERVER_URI}toggleService`,
                    {

                        "params": {

                            serviceId: product.value.id,
                            enabled: product.value.enabled

                        }

                    }
                ).
                    then((response) => {

                    });

            },
            setRate = function (e,k) {

                product.value.options[k].hourly = e;

            },
            getProfile = function (user_id) {

                Axios.get(
                    `${process.env.VUE_APP_SERVER_URI}getProfile`,
                    {

                        "params": {

                            userId:user_id

                        }

                    }
                ).
                    then((response) => {

                        if (response.status === 200) {

                            if (response.data) {

                                profile.value = response.data;

                            }

                            var geo = "";
                            try {

                                geo = JSON.parse(profile.value.geoip)

                            } catch(e) {

                                console.log(e);

                            }

                            if (geo.country) {

                                console.log(geo.country)

                                for (var i=0; i < countries.length; i++) {
                                  
                                    if (countries[i].code.toUpperCase() == geo.country.toUpperCase()) {

                                        product.value.country = countries[i].name;

                                    }

                                }

                            }

                        }

                    }).
                    catch((error) => {

                        throw error;

                    });

            },
            updateView = function (i) {

                console.log('CreateServices.vue updateView ' + i)

                if (!i) {

                    i = selectedMedia.value;

                }

                setTimeout(

                    () => {

                        const b = document.getElementById(`product-banner-${product.value.id}-${i}`);

                        if (productMedia.length === 0) {
                            
                            return;

                        }

                        if (b) {

                            if (productMedia[i].media_filename_preview) {

                                b.style.backgroundImage =
                                    `url('${process.env.VUE_APP_SERVER_URI}` + productMedia[i].media_filename_preview + "')";

                            } else {

                                b.style.backgroundImage =
                                    `url('${process.env.VUE_APP_SERVER_URI}` + productMedia[i].media_filename + "')";

                            }

                        }

                    },
                    300

                );

                if (serverConfig.view === "desktop") {

                    optionsContainer.value = "options-container-desktop";
                    respLayout.value = "resp-layout-desktop";

                } else if (serverConfig.view === "tablet") {

                    optionsContainer.value = "options-container-desktop";
                    respLayout.value = "resp-layout-desktop";

                } else {

                    optionsContainer.value = "options-container-mobile";
                    respLayout.value = "resp-layout-mobile";

                }

            };

        onMounted(() => {

            getProfile(userInfo.user_id);

            for (var i=0; i < product.value.media.length; i++) {
      
                updateView(i);

            }

            watch(

                () => serverConfig.view,
                () => {

                    updateView();

                }

            );

            if (!product.value.seller) {

                product.value.seller = userInfo.username;

            }

            productContainer.value = `product-container-${selectedMedia.value}`;

            watch(
                () => productMedia.length,

                (first, second) => {

                    productContainer.value = `product-container-${product.value.id}`;
                    updateView(productMedia.length-1);

                }

            );

        });

        return {"localize": translation.localize,
            icon,
            web3,
            countries,
            extractVideoID,
            getService,
            showTokenModal,
            showValidateModal,
            validateService,
            validateError,
            optionsContainer,
            respLayout,
            addOption,
            deleteOption,
            changeOptionKey,
            oldKey,
            newKey,
            getURL,
            getProfile,
            setLocal,
            setEnabled,
            setRate,
            saveProduct,
            updateView,
            selectCard,
            gotoPath,
            getRawIcon,
            genRandStr,
            showAddMessage,
            showAddModal,
            userInfo,
            detail,
            profile,
            product,
            carousel,
            productMedia,
            selectedMedia,
            addProductMedia,
            addCreativeFiles,
            currentFeature,
            productContainer,
            toolbar,
            deleteProductMedia,
            getMediaDimensions,
            getAccount,
            serverConfig,
            showTokenModal,
            "icon": icon.get};

    }
};

</script>
<style scoped>
.define-left {
}
.margin-top {
  margin-top: 20px;
}
.titlecolor {
  color: #22255C;
}
.paragraph {
  margin: 10px;
}
.title-mobile {
  margin-left: 0px;
}
.title-desktop {
  margin-left: 10px;
}
.text {
  color: #908ab9;
}
.createtitletext {
  color: #000;
}
.sub-title {
  color: #000;
  font-size: 1.2em;
}
.create-sub-header {
  margin-top: 20px;
  text-align: left;
  font-weight: bold;
  line-height: 35px;
  color: #22255C;
  font-size: 0.9em;
}
.sub-script {
  text-align: left;
  font-weight: bold;
  color: #afafaf;
  line-height: 30px;
  font-size: 0.8em;
}
.create-upload-box {
  height: 20px;
  border: 1px solid #efefef;
  border-radius: 15px;
  margin-top: 1px;
  margin-bottom: 1px;
  opacity: 0.5;
  background: url("../assets/upload-icon.svg") #efefef;
  background-position: center;
  background-size: 10%;
  background-repeat: no-repeat;
}
.create-upload-box {
  height: 100px;
  border: 1px solid #efefef;
  border-radius: 15px;
  margin-top: 10px;
  margin-bottom: 10px;
  opacity: 0.5;
  background: url("../assets/upload-icon.svg") #efefef;
  background-position: center;
  background-size: 10%;
  background-repeat: no-repeat;
}
.create-upload-box:hover {
  text-decoration: underline;
  opacity: 1.0;
  cursor: pointer;
}
.create-upload-box-thumbnail {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  color: grey;
  font-size: 2em;
  border: 1px solid #efefef;
  border-radius: 15px;
}
.create-upload-box-thumbnail:hover {
  text-decoration: underline;
  opacity: 1.0;
  cursor: pointer;
}
.add-media-thumbnail {
  font-size: 2em;
  color: #575757;
  font-size: 2em;
}
.file-items {
  padding: 10px;
  background-color: #eeebff;
  border-radius: 10px;
  font-size: 0.8em;
  margin-top: 10px;
  background: linear-gradient(1deg,#e5e5e5,transparent);
}
.file-name {
  color: #22255c;
  font-weight: bold;
  max-width: 40%;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 10px;
  white-space: nowrap;
}
.file-item {
  font-family: sans-serif;
  color: #a0a0a0;
  margin-right: 10px;
}
.remove-button {
  cursor: pointer;
  margin-right: 10px;
  font-weight: bold;
  color: #f7608a;
}
.create-service-preview-bg {
  flex-grow: 1;
  position: relative;
  overflow: hidden;
  border: 3px dashed #d295ff;
}
.create-service-preview-bg:hover {
  text-decoration: underline;
  opacity: 0.5;
}
.create-service-container {
  width: 33%;
}
.create-service-preview {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 40px;
  padding-left: 20px;
  border: 1px solid #fff;
  box-sizing: border-box;
  border-radius: 40px;
  justify-content: center;
}
.square {
  padding-bottom: 100%;
}
.smartphone-banner {
  padding-bottom: 16.667%;
}
/*
.ad191-1 {
  padding-bottom: 52.356%;
}
*/
.landscape {
  padding-bottom: 56.25%;
}
.portrait {
  padding-bottom: 177.78%;
}
.ad4-5 {
  padding-bottom: 125%;
}
.billboard {
  padding-bottom: 25.7%;
}
.leaderboard {
  padding-bottom: 12.36%;
}
.medium-rectangle {
  padding-bottom: 83.33%;
}
.halfpage {
  padding-bottom: 200%;
}
.wide-skyscraper {
  padding-bottom: 266%;
}
.skyscraper {
  padding-bottom: 375%;
}
.upload-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.service-upload-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.create-details {
  margin-top: 20px;
}
.create-targeting {
  margin-top: 20px;
}
h2.createtitletext {
  text-align: left;
  color: #22255C;
}
.vspace {
  width: 100%;
  height: 20px;
}
.nowrap {
  flex-wrap: nowrap;
}
.create-item {
  margin-top: 20px;
}
.spend-item {
  margin-top: 20px;
  width: 150px;
}
.create-service-unit {
  color: #22255c;
  margin-left: 20px;
  font-weight: bold;
  font-size: 1.0em;
  margin-right: 10px;
  flex-wrap: nowrap;
}
.pointer {
  cursor: pointer;
}
.create-save-service-button {
  display: flex;
  margin-top: 20px;
  margin-bottom: 10px;
  align-items: center;
  width: fit-content;
  font-family: Comfortaa,sans-serif;
  text-align: left;
  white-space: nowrap;
  color: white;
  height: 50px;
  font-weight: bold;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 30px;
  border: none;
  box-shadow: 0px 15px 20px 2px rgb(139 106 224 / 33%);
  cursor: pointer;
  background: linear-gradient(338deg,#ff7c88,#ff46a4)
}
.create-service-title {
  font-weight: 600;
  font-family: sans-serif;
  text-decoration: none;
  color: var(--msft-card-font-color);
  font-size: var(--msft-article-heading-font-size, 1.2em);
  line-height: var(--msft-article-heading-line-height, 24px);
  -webkit-line-clamp: var(--heading-max-lines, 3);
  text-align: center;
  margin: 10px;
  outline: 0px;
}
.flex-row {
  display: flex;
  flex-direction: row;
}
.flex-col {
  display: flex;
  flex-direction: column;
}
.step-1-container {
  display: flex;
  flex-direction: row;
  width: 1200px;
}
.step-2-3-container {
  display: flex;
  flex-direction: row;
  justify-content: inherit;
}
.step-2-3-container-left {
  width: 50%;
}
.contract-items {
  display: flex;
  margin-top: 18px;
}
.category {
  display: flex;
}
.create-dropdown-item {
  margin-right: 10px;
}
input#create-service-file-upload-thumbnail {
  display: none;
}
input#create-service-file-upload {
  display: none;
}
label.create-service-file-upload {
  display: block;
  width: 100%;
  height: 100%;
  background: none;
  padding: 0;
  color: inherit;
  border: none;
  cursor: pointer;
  outline: inherit;
}
label.create-service-file-upload-thumbnail {
  display: block;
  background: none;
  padding: 0;
  color: inherit;
  border: none;
  cursor: pointer;
  outline: inherit;
}
.preview-container {
  margin-left: 20px;
}
.media-preview {
  width: 100%;
  height: 64px;
  max-width: 300px;
  overflow: none;
  cursor: pointer;
  border-radius: 15px;
  aspect-ratio: 1/1;
  object-fit: cover;
  background-size: cover;
  background-position: 50%;
  transition: all 500ms ease-in-out;
}
.media-preview-thumbnail {
  width: 64px;
  height: 64px;
  overflow: none;
  cursor: pointer;
  border-radius: 15px;
  aspect-ratio: 1/1;
  object-fit: cover;
  background-size: cover;
  background-position: 50%;
  transition: all 500ms ease-in-out;
}
.img media-preview {
  width: 100%;
}
.media-thumbnail {
  width: 64px;
  height: 64px;
  margin-right: 5px;
  margin-top: 5px;
  overflow: none;
  cursor: pointer;
  border-radius: 15px;
  aspect-ratio: 1/1;
  object-fit: cover;
  background-size: cover;
  background-position: 50%;
  transition: all 500ms ease-in-out;
}
.img media-thumbnail {
  width: 100%;
}
.product-preview-mobile {
  width: 100%;
}
.product-preview-desktop {
  width: 60%;
}
.product-banner-desktop {
  width: 100%;
  border-radius: 25px;
  background-size: cover;
  aspect-ratio: 2/1;
  object-fit: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.product-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 25px;
}
.service-carousel {
  width: 100%;
}
.service-thumbnails {
  display: flex;
}
.edit-service-card-preview {
  width: 277px;
  border: 2px dashed;
  border-radius: 20px;
  padding: 10px;
}
.options-container-desktop {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.options-container-mobile {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.resp-layout-desktop {
  width: 30%;
  margin-right: 20px;
}
.resp-layout-mobile {
  width: 100%;
  margin-right: auto;
}
.add-features {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: end;
  margin-left: 10px;
  margin-bottom: 20px;
}
.rem-features {
  margin-left: 10px;
  color: #da2671;
}
.rem-features:hover {
  text-decoration: underline;
  cursor: pointer;
}
.offer-container-outer {
  padding: 1px;
  border-radius: 20px;
  background: radial-gradient(ellipse at top left, #3a9ae7 0, #b53cd3 62%, #041534 100%);
}
.offer-container-inner {
  color: #2d2d2d;
  margin: 3px;
  padding: 10px;
  border-radius: 15px;
  background: white;
}
.feature-tick {
  width: 20px;
  height: 20px;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-right: 5px;
}
.order-detail {
  margin-bottom: 10px;
}
.order-description {
  margin-bottom: 10px;
}
.quill-editor {
  height: 400px;
  font-size: 1.5em;
  margin-top: 20px;
}
.save-under-account {
  width: fit-content;
  margin: 33px;
  font-size: 0.9em;
  borer: 1px solid #505050;
}
.quill-input {
  border: none !important;
  font-size: 1.5em !important;
  height: 500px;
}
.ql-toolbar.ql-snow { border: none !important;}
.delete-button-small:hover {
  text-decoration: underline;
  cursor: pointer;
  color: #da2671;
}
.delete-button-small {
  margin: 6px 0px;
  font-size: 0.8em;
  pointer: cursor;
}
.margin-top-100 {
  margin-top: 100px;
  margin-bottom: 100px;
  align-items: center;
  justify-content: space-between;
}
.ql-editor {
  font-size: 18px;
}
.offer-description {
  min-height: 150px;
}
.local-switch-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-left: 10px;
}
.local-container {
  display: flex;
  max-width: 600px;
  justify-content: space-between;
}
.local-switch {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}
.location-input {
  font-color: #505050;
}
.c-input-title {
  margin-top: 15px;
  margin-bottom: 7px;
  font-size: .75em;
  font-weight: 700;
  color: #a046ff;
  text-align: left;
}
.add-option {
  border-radius: 8px;
  margin: 20px;
  padding: 30px;
  background-color: #efefef;
  border: 2px solid #efefef;
  color: #333;
  height: fit-content;
  font-weight: bold;
}
.add-option:hover {
  border: 2px solid #999;
  cursor: pointer;
}
.online-local-text {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-left: 10px;
  margin-top: 20px;
}
.flex-row-wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
</style>
