<template>
  <div
    :class="docMain"
  >
    <head>
      <meta
        http-equiv="Content-Type"
        content="text/html; charset=UTF-8"
      >
      <meta
        name="generator"
        content="pdftohtml 0.36"
      >
    </head>
    <title><b>Terms & Conditions</b></title>
    <div class="pretty-text">
      <br />
      <h2> <b> {{ localize("TEXT_TERMS_CONDITIONS") }} </b></h2>
      <br />
      <br />
      <b>{{ localize("TERMS_INTRODUCTION") }}</b>
      <br />
      <br />
      <div>{{ localize("TERMS_INTRODUCTION_A") }}</div>
      <br />
      <div>{{ localize("TERMS_INTRODUCTION_B") }}</div>
      <br />
      <div>{{ localize("TERMS_INTRODUCTION_C") }}</div>
      <br />
      <div>
        <span>
          {{ localize("TERMS_INTRODUCTION_D") }} 
          <a href="mailto:contact@thumpr.xyz" style="text-decoration: none; color: inherit;"></a>
        </span>
      </div>
      <br />
      <b>{{ localize("TERMS_SERVICES_PROVIDED") }}</b>
      <br />
      <br />
      <div> {{ localize("TERMS_SERVICES_PROVIDED_A") }}</div>
      <br />
      <div> {{ localize("TERMS_SERVICES_PROVIDED_B") }}</div>
      <br />
      <div> {{ localize("TERMS_SERVICES_PROVIDED_C") }}</div>
      <br />
      <div> {{ localize("TERMS_SERVICES_PROVIDED_D") }}</div>
      <br />
      <b> {{ localize("TERMS_COMMUNITY_STANDARDS") }}</b>
      <br />
      <br />
      <div> {{ localize("TERMS_COMMUNITY_STANDARDS_A") }}</div>
      <br />
      <ul>
      <li> {{ localize("TERMS_COMMUNITY_STANDARDS_B") }}</li>
      <li> {{ localize("TERMS_COMMUNITY_STANDARDS_C") }}</li>
      <li> {{ localize("TERMS_COMMUNITY_STANDARDS_D") }}</li>
      <li> {{ localize("TERMS_COMMUNITY_STANDARDS_E") }}</li>
      <li> {{ localize("TERMS_COMMUNITY_STANDARDS_F") }}</li>
      <li> {{ localize("TERMS_COMMUNITY_STANDARDS_G") }}</li>
      </ul>
      <br />
      <div> {{ localize("TERMS_COMMUNITY_STANDARDS_H") }}</div>
      <br />
      <b>{{ localize("TERMS_PAYMENT") }}</b>
      <br />
      <br />
      <div> {{ localize("TERMS_PAYMENT_A") }}</div>
      <br />
      <div> {{ localize("TERMS_PAYMENT_B") }}</div>
      <br />
      <div> {{ localize("TERMS_PAYMENT_C") }}</div>
      <br />
      <b> {{ localize("TERMS_LIMITATION_OF_LIABILITY") }}</b>
      <br />
      <br />
      <div> {{ localize("TERMS_LIMITATION_OF_LIABILITY_A") }}</div>
      <br />
      <div> {{ localize("TERMS_LIMITATION_OF_LIABILITY_B") }}</div>
      <br />
      <div> {{ localize("TERMS_LIMITATION_OF_LIABILITY_C") }}</div>
    </div>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <hr>
  </div>
</template>
<script>

import {inject, onMounted, reactive, ref, watch} from "vue";
import {translationStore} from "@/stores/translation";

export default {
    "name": "Terms",
    "components": {
    },
    "props": {
    },
    setup () {

        const serverConfig = inject("serverConfig"),
            translation = translationStore(),
            userInfo = inject("userInfo"),
            docMain = ref(""),
            thumprDocs = ref(""),
            updateView = function () {

                if (serverConfig.view === "desktop") {

                    thumprDocs.value = "thumpr-docs-desktop";
                    docMain.value = "doc-main-desktop";

                } else if (serverConfig.view === "laptop") {

                    thumprDocs.value = "thumpr-docs-desktop";
                    docMain.value = "doc-main-laptop";

                } else if (serverConfig.view === "tablet") {

                    thumprDocs.value = "thumpr-docs-desktop";
                    docMain.value = "doc-main-tablet";

                } else if (serverConfig.view === "mobile") {

                    thumprDocs.value = "thumpr-docs-mobile";
                    docMain.value = "doc-main-mobile";

                } else {

                    thumprDocs.value = "thumpr-docs-mobile";
                    docMain.value = "doc-main-mobile";

                }

            };

        onMounted(() => {

            watch(

                () => serverConfig.view,
                () => {

                    updateView();

                }

            );

            updateView();

        });

        return {"localize": translation.localize,
            translation,
            docMain,
            thumprDocs,
            updateView};

    }
};

</script>
<style scoped>
.doc-main-desktop {
  display: flex; height: 100%; flex-direction: column; overflow: hidden;
  font-size: 0.9em;
  line-height: 30px;
  max-width: 1200px;
  padding: 10px;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}
.doc-main-laptop {
  display: flex; height: 100%; flex-direction: column; overflow: hidden;
  font-size: 0.9em;
  line-height: 30px;
  max-width: 1200px;
  margin: 10px;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}
.doc-main-mobile {
  display: flex; height: 100%; flex-direction: column; overflow: hidden;
  font-size: 0.9em;
  line-height: 30px;
  padding: 10px;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}
.doc-main-tablet {
  display: flex; height: 100%; flex-direction: column; overflow: hidden;
  font-size: 0.9em;
  line-height: 30px;
  max-width: 1000px;
  margin: 10px;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}
iframe {
  border: none;
}
h1.doc {
  margin-top: 100px;
  margin-bottom: 100px;
  line-height: 50px;
}
.thumpr-docs-desktop {
  flex-grow: 1; border: none; margin: 0; padding: 0;
  margin-top: 100px;
  max-width: 1000px;
}
.thumpr-docs-mobile {
  flex-grow: 1; border: none; margin: 0; padding: 0;
  margin-top: 50px;
  max-width: 500px;
}
.footer-item {
  margin: 10px;
  cursor: pointer;
  color: #505050;
}
</style>

